import React from "react";
import "../styles/App.css";
import { useMediaQuery } from "react-responsive";
import testimonialImage from "../assets/sarah.jpg";

function VideoPlayer(props) {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <div
      className="testimonial-container"
      style={{
        padding: isMobile ? 16 : 24,
        flexDirection: "row",
      }}
    >
      {" "}
      <h4 style={{ fontSize: isMobile ? "auto" : 25 }}>
        "{props.testimonial.description}"
      </h4>
      <div id="testimonial-identity-container">
        <img
          style={{
            marginRight: 8,
            borderRadius: isMobile ? 20 : 40,
            width: isMobile ? 40 : 80,
            height: isMobile ? 40 : 80,
            objectFit: "cover",
          }}
          src={props.testimonialImage}
        />
        <div id="testimonial-identity-details-container">
          <a
            style={{
              fontWeight: "bold",
              fontSize: isMobile ? "auto" : 20,
            }}
          >
            {props.testimonial.name}
          </a>
          <a
            style={{
              fontSize: isMobile ? "auto" : 20,
            }}
          >
            {props.testimonial.occupation}
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
